<template>
  <div>
    <div id="case" :class="'html' + sort">
      <div
        id="content"
        class="content"
        :class="'html' + colors"
        ref="createCrad"
      >
        <el-image :style="cardImgOne" :src="cardImg"></el-image>
        <div class="username" :style="user">
          <div class="nameMini" :style="nameMini">
            {{ createForm.username }}
          </div>
        </div>
        <div class="fontTop" :style="fontTop">
          <div>年龄：{{ createForm.age }}</div>
          <div>性别：{{ createForm.gender === 0 ? "女" : "男" }}</div>
          <div>职业：{{ createForm.job }}</div>
          <div v-if="createForm.hSecret === 0">
            身高：{{ createForm.height + " cm" }}
          </div>
          <div v-if="createForm.hSecret === 1">身高：保密</div>
          <div v-if="createForm.wSecret === 0">
            体重：{{ createForm.weight + " Kg" }}
          </div>
          <div v-if="createForm.wSecret === 1">体重：保密</div>
          <div v-if="createForm.sSecret === 0">
            收入：{{ createForm.salary + " 万/年(RMB)" }}
          </div>
          <div v-if="createForm.sSecret === 1">收入：保密</div>
          <div>学历：{{ createForm.education }}</div>
          <div>国籍：{{ createForm.nationality }}</div>
          <div>祖籍：{{ createForm.hometown }}</div>
          <div>现居：{{ createForm.location }}</div>
        </div>
        <div class="speak" :style="speak">听ta说</div>
        <div class="fontFoot" :style="fontFoot">
          <div class="mini">兴趣爱好：{{ createForm.hobby }}</div>
          <div class="mini" style="margin-top: 6px">
            情感经历：{{ createForm.emotionalExp }}
          </div>
          <div class="inde">
            婚姻状况：{{ createForm.maritalStatus | maritalStatusItem }}
          </div>
          <div class="mini">对另一半的期许：{{ createForm.hopes }}</div>
        </div>
        <!-- 提示 -->
        <div class="tips" :style="tips">
          红娘提示：网络交友，存在一定风险，会面需谨慎，谨防上当受骗
        </div>
        <!-- 编号 -->
        <div class="userNum" :style="userNum">{{ createForm.userNum }}</div>
        <!-- 图片一（顶部） -->
        <div class="cardI" @click="getCanvasTop" :style="cardTopImg">
          <el-image
            :style="cardImage"
            :src="createForm.photo[0].photoUrl"
          ></el-image>
          <!-- <el-image
            :style="watermarkOne"
            :src="require('../../assets/img/watermark/watermark.png')"
          ></el-image> -->
        </div>
        <!-- 图片二（底部） -->
        <div class="cardI" :style="cardFootImg" @click="getCanvasFoot">
          <el-image
            :style="cardImage"
            :src="createForm.photo[1].photoUrl"
          ></el-image>
          <!-- <el-image
            :style="watermarkOne"
            :src="require('../../assets/img/watermark/watermark.png')"
          ></el-image> -->
        </div>
      </div>
      <div class="cardBtn" :style="crearCard">
        <el-button class="vip1" @click.native="updateVip(1)"
          >切换普通卡</el-button
        >
        <el-button class="vip2" @click.native="updateVip(2)"
          >切换VIP普卡</el-button
        >
        <el-button class="vip3" @click.native="updateVip(3)"
          >切换VIP金卡</el-button
        >
        <el-button class="vip4" @click.native="updateVip(4)"
          >切换VIP铂金卡</el-button
        >
      </div>
      <el-button
        class="crearCard"
        @click.native="clickConfirm"
        :style="crearCard"
        >生成相亲卡</el-button
      >
      <el-image style="display: none" :src="images"></el-image>
    </div>
    <div class="hello" id="canvasImg" v-show="canvasImg">
      <p>预览图</p>
      <div class="before"></div>
      <el-button
        style="margin: 12px 0 12px 12px"
        type="primary"
        @click="sureSava"
      >
        裁剪
      </el-button>
      <el-button
        style="margin: 12px 0 12px 12px"
        type="primary"
        @click="rotateX"
      >
        左转
      </el-button>
      <el-button
        style="margin: 12px 0 12px 12px"
        type="primary"
        @click="rotateY"
      >
        右转
      </el-button>
      <div class="container">
        <div class="img-container">
          <img :src="cropperImg" ref="image" alt="" />
        </div>
      </div>
      <!-- <div class="container">
        <div class="img-container" v-show="setShow">
          <img :src="cropperImg" ref="image" alt="" />
        </div>
        <div class="img-container" v-show="!setShow">
          <img :src="cropperImg2" ref="image2" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

import { request } from "../../API/request";

export default {
  data() {
    return {
      myCropper: null, // 裁剪图片
      screenHeight: window.innerHeight, // 浏览器高度
      pei: window.innerHeight / 1543, // 倍率
      canvasImg: false, // 图片裁剪框
      cardImg: "", // 相亲卡图片
      createForm: {}, // 相亲卡图片信息
      canvasCard: {
        photo: [],
      },
      cardImgOne: {}, // 相亲背景图
      user: {}, // 姓名样式
      nameMini: {}, // 姓名长度样式
      date: "", // 卡片日期
      fontTop: {}, // 顶部信息样式
      speak: {}, // 听ta说样式
      fontFoot: {}, // 底部样式
      tips: {}, // 提示样式
      userNum: {}, // 编号样式
      cardTopImg: {}, // 头部图样式
      cardFootImg: {}, // 底部图样式
      crearCard: {}, // 生成相亲卡按钮样式
      images: "", // 临时图片地址
      cropperImg: "", // 裁剪的图片
      cropperImg2: "", // 裁剪的图片
      type: "top", // 图片操作来源
      colors: "",
      setShow: true,
    };
  },
  created() {
    let d = new Date();
    this.date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    try {
      let info = this.$route.params.form;
      console.log(info);
      this.canvasCard.photo = JSON.parse(JSON.stringify(info.photo));
      // this.$set(
      //   this.canvasCard,
      //   "photo",
      //   JSON.parse(JSON.stringify(info.photo))
      // );
      this.createForm = info;
      if (info.vipStatus === -1 || info.vipLevel === 0) {
        this.cardImg = require("../../assets/img/card/one.jpg");
      } else if (info.vipLevel === 1) {
        this.cardImg = require("../../assets/img/card/two.jpg");
      } else if (info.vipLevel === 10) {
        this.cardImg = require("../../assets/img/card/three.jpg");
      } else if (info.vipLevel === 100) {
        this.cardImg = require("../../assets/img/card/four.jpg");
      } else {
        this.cardImg = require("../../assets/img/card/one.jpg");
      }
    } catch (err) {
      this.$router.push("/Home");
    }

    // 图片适应
    let p = this.pei;
    this.cardImgOne = {
      // 相亲背景图
      width: 1080 * p + "px",
      height: this.screenHeight + "px",
      "vertical-align": "middle",
    };
    this.user = {
      top: 70 * p + "px",
      left: 60 * p + "px",
      "font-size": 70 * p + "px",
    };
    this.nameMini = {
      "min-width": 469 * p + "px",
      "min-height": 469 * p + "px",
    };
    this.fontTop = {
      top: 180 * p + "px",
      "font-size": 42 * p + "px",
      left: 590 * p + "px",
    };
    this.speak = {
      width: 469 * p + "px",
      top: 800 * p + "px",
      "font-size": 70 * p + "px",
      left: 60 * p + "px",
    };
    this.fontFoot = {
      width: 450 * p + "px",
      height: 530 * p + "px",
      top: 930 * p + "px",
      left: 60 * p + "px",
      "font-size": 32 * p + "px",
    };
    this.tips = {
      bottom: 20 * p + "px",
      left: 100 * p + "px",
      "font-size": 26 * p + "px",
    };
    this.userNum = {
      bottom: 18 * p + "px",
      left: 920 * p + "px",
      "font-size": 28 * p + "px",
    };
    this.cardImage = {
      width: 469 * p + "px",
      height: 550 * p + "px",
    };
    // this.watermarkOne = {
    //   position: "absolute",
    //   width: 469 * p + "px",
    //   height: 550 * p + "px",
    // };
    this.cardTopImg = {
      position: "absolute",
      width: 469 * p + "px",
      height: 550 * p + "px",
      top: 200 * p + "px",
      left: 60 * p + "px",
    };
    this.cardFootImg = {
      position: "absolute",
      width: 469 * p + "px",
      height: 550 * p + "px",
      top: 900 * p + "px",
      right: 60 * p + "px",
    };
    this.crearCard = {
      left: 1080 * p + "px",
    };
  },
  methods: {
    // 初始化图层
    init(img) {
      this.myCropper = new Cropper(this.$refs[img], {
        viewMode: 1,
        dragMode: "none",
        initialAspectRatio: 1,
        preview: ".before",
        background: false,
        autoCropArea: 0.9,
        zoomOnWheel: false,
        autoCropWidth: 200,
        autoCropHeight: 400,
        aspectRatio: 0.853,
      });
    },
    // 截取图片
    sureSava() {
      let i = this.myCropper
        .getCroppedCanvas({
          imageSmoothingQuality: "high",
        })
        .toDataURL("image/jpeg");
      let that = this;
      setTimeout(function () {
        that.images = i;
        if (that.type == "top") {
          that.createForm.photo[0].photoUrl = i;
        } else if (that.type == "foot") {
          that.createForm.photo[1].photoUrl = i;
        }
      }, 30);
    },
    // 向左旋转
    rotateX() {
      this.myCropper.rotate(90);
    },
    // 向右旋转
    rotateY() {
      this.myCropper.rotate(-90);
    },
    // 生成相亲卡
    clickConfirm() {
      let that = this;
      let text;
      let vip = that.createForm.vipLevel;
      console.log(that.createForm);
      // if (that.createForm.vipStatus === -1 || vip === 0) {
      //   text = "普通客户";
      // } else if (vip === 1) {
      //   text = "普卡VIP客户";
      // } else if (vip === 10) {
      //   text = "金卡VIP客户";
      // } else if (vip === 100) {
      //   text = "铂金卡VIP客户";
      // }
      that
        // .$confirm(`当前客户为 ${text} ，是否确认无误继续生成？`, "温馨提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "error",
        // })
        .$confirm(`当前客户为普通客户 ，是否确认无误继续生成？`, "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          html2canvas(that.$refs.createCrad, {
            useCORS: true,
            logging: false,
            tainttest: true,
          }).then((canvas) => {
            console.log("底色", that.colors);
            let dataURL = canvas.toDataURL("image/jpg");
            var form = document.forms[0];
            var formData = new FormData(form);
            formData.append("files", that.convertBase64UrlToBlob(dataURL));
            formData.append("cid", that.createForm.id);
            formData.append("userNum", that.createForm.userNum);
            // formData.append("vipLevel", that.colors);
            const loading = that.$loading({
              lock: true,
              text: "拼命上传中",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)",
            });
            request({
              url: "/customer/addCard",
              method: "POST",
              data: formData,
            }).then((res) => {
              if (res.code === 200) {
                loading.close();
                that.$router.push("/business");
              } else {
                that.$message.error(res.message);
              }
            });
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    getCanvasTop() {
      let that = this;
      // this.setShow = true;
      // that.canvasImg = false;

      that.cropperImg = that.canvasCard.photo[0].photoUrl;
      that.canvasImg = true;
      that.type = "top";
      if(this.myCropper) this.myCropper.destroy()
      that.$nextTick(() => {
        that.init("image");
      });
      // setTimeout(function () {
      //   // that.canvasImg = true;
      //   // setTimeout(function () {
      //   // that.$nextTick(()=>{
      //   //   that.init();
      //   // })
      //   // }, 30);
      // }, 10);
    },
    // 点击底部图片
    getCanvasFoot() {
      let that = this;
      // this.setShow = false;
      that.cropperImg = that.canvasCard.photo[1].photoUrl;
      that.canvasImg = true;
      that.type = "foot";
      if(this.myCropper) this.myCropper.destroy()
      that.$nextTick(() => {
        that.init("image");
      });
      // let that = this;
      // that.canvasImg = false;
      // that.type = "foot";
      // that.cropperImg = that.canvasCard.photo[1].photoUrl;
      // console.log(that.cropperImg);
      // setTimeout(function () {
      //   that.canvasImg = true;
      //   // setTimeou
      // }, 10);
    },
    // 点击顶部图片
    // getCanvasTop() {
    //   let that = this;
    //   this.setShow = true;
    //   // that.canvasImg = false;

    //   that.cropperImg = that.canvasCard.photo[0].photoUrl;
    //   that.canvasImg = true;
    //   that.type = "top";
    //   if(this.myCropper) this.myCropper.destroy()
    //   that.$nextTick(() => {
    //     that.init("image");
    //   });
    //   // setTimeout(function () {
    //   //   // that.canvasImg = true;
    //   //   // setTimeout(function () {
    //   //   // that.$nextTick(()=>{
    //   //   //   that.init();
    //   //   // })
    //   //   // }, 30);
    //   // }, 10);
    // },
    // // 点击底部图片
    // getCanvasFoot() {
    //   let that = this;
    //   this.setShow = false;
    //   that.cropperImg2 = that.canvasCard.photo[1].photoUrl;
    //   that.canvasImg = true;
    //   that.type = "foot";
    //   if(this.myCropper) this.myCropper.destroy()
    //   that.$nextTick(() => {
    //     that.init("image2");
    //   });
    //   // let that = this;
    //   // that.canvasImg = false;
    //   // that.type = "foot";
    //   // that.cropperImg = that.canvasCard.photo[1].photoUrl;
    //   // console.log(that.cropperImg);
    //   // setTimeout(function () {
    //   //   that.canvasImg = true;
    //   //   // setTimeou
    //   // }, 10);
    // },
    // 操作生成file文件
    convertBase64UrlToBlob(urlData) {
      var bytes = window.atob(urlData.split(",")[1]); //去掉url的头，并转换为byte
      //处理异常,将ascii码小于0的转换为大于0
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },
    // 修改用户卡片等级
    updateVip(e) {
      switch (e) {
        case 1:
          this.cardImg = require("../../assets/img/card/one.jpg");
          this.colors = 0;
          break;
        // d;
        case 2:
          this.cardImg = require("../../assets/img/card/two.jpg");
          this.colors = 1;
          break;
        case 3:
          this.cardImg = require("../../assets/img/card/three.jpg");
          this.colors = 10;
          break;
        case 4:
          this.cardImg = require("../../assets/img/card/four.jpg");
          this.colors = 100;
          break;
      }
    },
  },
  computed: {
    sort() {
      let vip = this.createForm.vipLevel;
      if (this.createForm.vipStatus === -1 || vip === 0) {
        return 0;
      } else if (vip === 1) {
        return 1;
      } else if (vip === 10) {
        return 10;
      } else if (vip === 100) {
        return 100;
      }
    },
  },
  filters: {
    maritalStatusItem(item) {
      if (item === 1) {
        return "未婚";
      } else if (item === 2) {
        return "分居";
      } else if (item === 3) {
        return "丧偶";
      } else if (item === 4) {
        return "离异无孩";
      } else if (item === 5) {
        return "离异不带孩";
      } else if (item === 6) {
        return "离异带孩";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.html0 {
  color: #333333;
}
.html1 {
  color: #db6564;
}
.html10 {
  color: #7a3a1e;
}
.html100 {
  color: #9b9291;
}
#case {
  display: flex;
  overflow: hidden;
  .crearCard {
    position: absolute;
    top: 100px;
    color: var(--white);
    background-color: var(--pink);
  }
  .cardBtn {
    position: absolute;
    top: 20px;

    .vip1 {
      background-color: #333333;
    }
    .vip2 {
      background-color: #db6564;
    }
    .vip3 {
      background-color: #7a3a1e;
    }
    .vip4 {
      background-color: #9b9291;
    }
    .el-button {
      color: var(--white);
    }
  }

  .content {
    position: relative;
  }
  .username {
    position: absolute;
    text-align: center;
    font-weight: 600;
  }
  .fontTop {
    position: absolute;
    line-height: 1.4;
  }
  .speak {
    position: absolute;
    text-align: center;
    font-weight: 600;
  }
  .fontFoot {
    position: absolute;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .inde {
      line-height: 2.3;
    }
    .mini {
      line-height: 1.5;
    }
  }
  .tips {
    position: absolute;
  }
  .userNum {
    position: absolute;
    font-weight: 600;
  }

  .cardI {
    position: absolute;
    border-radius: 6%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
#canvasImg {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--white);

  .container {
    display: flex;
  }
  .before {
    width: 200px;
    height: 200px;
    overflow: hidden;
    /* 这个属性可以得到想要的效果 */
  }
  .img-container {
    width: 600px;
    height: 600px;
    // overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .afterCropper {
    flex: 1;
    margin-left: 20px;
    border: 1px solid salmon;
    text-align: center;
  }
  .afterCropper img {
    width: 150px;
    margin-top: 30px;
  }
}
</style>